import { startTransition, useEffect, useState } from "react";

/** UseState, but always runs a concurrent transition */
export function useTransitionState<T = undefined>(initialState?: T | (() => T)) {
    const [state, setState] = useState(initialState);
    function indirectSetState(newState: T) {
        startTransition(() => setState(newState));
    }
    return [state, indirectSetState] as [T, (o: T) => void];
}

export function useOneShot(input: boolean | undefined) {
    const [oneShot, setOneShot] = useState(input);
    useEffect(() => {
        input && setOneShot(true);
    }, [input]);
    return oneShot;
}
